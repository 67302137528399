<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" style="width: 26rem">
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">消费盲反规则配置</div>
          <el-form-item label="专区商品">
            每购买一单增加可分红资格
            <el-input-number v-model="form.consume_grant_ticket_num"></el-input-number>
            次
          </el-form-item>
          <el-form-item label="结算方式">
            <el-radio-group v-model="form.blind_award_mode">
              <el-radio v-for="(item,key) in state" :key="key" :label="item.value">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="随机分配每笔最高金额">
            <el-input-number v-model="form.random_max_fee"></el-input-number>元
            <div class="y-desc">为0时不指定</div>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>

const BlindAwardModeState = {
  normal:{
    value:1,
    label:"平均发放"
  },
  random:{
    value:2,
    label:"随机发放"
  }
}

export default {
  name: "conf",
  components:{
  },
  data() {
    return {
      state:BlindAwardModeState,
      form:{
        consume_grant_ticket_num:0,
        blind_award_mode:0,
        random_max_fee:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.blindAward.conf().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.blindAward.confEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>